import { getCurrentTrip } from "./trip";
import { logger } from "./logger";

/**
 * @returns the basket associated with the trip in session, i.e, current trip.
 */
export async function getBasket(): Promise<any> {
    logger.log('Calling getBasket')
    const { id: tripId, tripAccessToken } = getCurrentTrip();
    const getBasket = await fetch(
        `https://api.gordiansoftware.com/v2.2/trip/${tripId}/basket`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tripAccessToken}`
            }
        }
    )
    const basket = await getBasket.json()
    return basket
}

export async function getSearchFromBasket(basket: { [x: string]: any; }, type: string = 'seat'): Promise<any> {
    const basketKeys = Object.keys(basket);
    if (basketKeys.length === 0) {
        return null
    }
    const { id: tripId, tripAccessToken } = getCurrentTrip();
    let product;
    for (const product_id in basket) {
        const item = basket[product_id];
        if (item.product_type === type) {
            product = item;
            break;
        }
    }
    if (!product) {
        return null
    }

    const { product_id } = product;
    const productDetails = await fetch(`https://api.gordiansoftware.com/v2.2/trip/${tripId}/product/${product_id}/search_id`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${tripAccessToken}`
            },
        })
    const { search_id } = await productDetails.json();

    const searchResponse = await fetch(
        `https://api.gordiansoftware.com/v2.2/trip/${tripId}/search/${search_id}${type === 'seat' ? '?raw=TRUE' : ''}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${tripAccessToken}`
            },
        }
    );

    const response = await searchResponse.json();
    try {
        if (type === 'bag' && response.product_status.bag.status === "success") {
            return response
        } else {
            const { raw_response } = response;
            const { display_json } = raw_response;
            // If the search from v2 doesn't pass through v1, then then the raw search response should already be in the correct format, so just return that
            if (!display_json) {
                return searchResponse
            }
            const { availability_v2_json } = display_json;
            return availability_v2_json;
        }
    } catch {
        const error = new Error("Seat search from basket items failed");
        logger.error(error)
        logger.sentryError(error)
        return null;
    }
}

export async function clearBasketSeats(): Promise<any> {
    logger.log('Clearing basket of all seats')
    const { id: tripId, tripAccessToken } = getCurrentTrip();
    return await fetch(
        `https://api.gordiansoftware.com/v2.2/trip/${tripId}/basket/seat`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tripAccessToken}`
            },
            body: '[]'
        }
    )
}

